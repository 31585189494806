import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useDebounce from '../../../../hooks/useDebounce';
import { setDescription } from '../../../../redux/slices/prothese/protheseSlice';
import { dispatch, useSelector } from '../../../../redux/store';
export default function TreatmentDescription() {
    const [descriptionState, setDescriptionState] = useState('');
    const { description } = useSelector((state) => state.dent);
    useEffect(() => {
        setDescriptionState(description);
    }, []);
    useDebounce(() => {
        dispatch(setDescription(descriptionState));
    }, 200, [descriptionState]);
    return (_jsx(Box, { sx: { display: 'flex', gap: '15px' }, children: _jsxs(Card, { sx: {
                height: 'fit-content !important',
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                justifyContent: 'start',
                padding: '10px 20px',
                marginTop: '15px',
            }, children: [_jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { marginTop: '20px', fontWeight: 'bold' }, children: "Laissez un commentaire:" }), _jsx(TextField, { sx: { marginTop: '10px' }, fullWidth: true, multiline: true, rows: 8, value: descriptionState, onChange: (e) => {
                        setDescriptionState(e.target.value);
                    }, label: 'Commentaire' })] }) }));
}
